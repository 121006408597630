import React from 'react';
import { IsAdultPage } from '../../utils/page';

// レビュー投稿画面で表示する注意書き
const ReviewCommonNotes = () => (
  <div className="pfReview__note">
    <ul>
      <li>
        <span className="red">*</span>
        マークは入力必須項目です。
      </li>
      <li>
        商品タイトル1つにつき、投稿できるレビューは1件です。
        <br />
        商品によっては、複数商品やシリーズを通しての投稿となる場合があります。
      </li>
      <li>絵文字など環境依存文字は、文字化けする可能性があります。</li>
      <li>
        書き方や詳細については、
        <a
          href={`https://support.dmm.${IsAdultPage() ? 'co.jp' : 'com'}/review`}
          rel="noopener noreferrer"
          target="_blank"
        >
          ヘルプページ
        </a>
        をご覧ください。
      </li>
      <li>
        不具合や視聴方法についての質問は、
        {
          IsAdultPage()
            ? <a href="https://inquiry.dmm.co.jp/">サポートセンター</a>
            : <a href="https://inquiry.dmm.com/">サポートセンター</a>
        }
        からお問い合わせください。
      </li>
    </ul>
  </div>
);

export default ReviewCommonNotes;
